<template>
  <div>
    <b-container fluid>
      <b-card
        no-body
        title="Nguyện vọng tuyển sinh"
      >
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label-for="admissionRoundId"
                >
                  <v-select
                    v-model="filter.admissionRoundId"
                    :options="admissionRounds"
                    :reduce="option => option.value"
                    placeholder="Đợt tuyển sinh"
                    @input="readAdmissionForm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label-for="admissionFormId"
                >
                  <v-select
                    v-model="filter.admissionFormId"
                    :options="dataListAdmissionForm"
                    :reduce="option => option.value"
                    placeholder="Phương thức tuyển sinh"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="4"
              >
                <b-row>
                  <b-col
                    cols="12"
                    class="text-right"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mr-1"
                      @click="btnSearchClick()"
                    >
                      <feather-icon
                        icon="SearchIcon"
                        class="align-text-top"
                      />
                      Tìm kiếm
                    </b-button>
                    <b-button
                      v-if="creatable"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-modal.priorityZoneSaveModal
                      variant="success"
                      size="sm"
                      class="mr-1"
                      @click="onCreate"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="PlusIcon" /> Thêm mới
                      </span>
                    </b-button>
                    <b-button
                      v-if="creatable"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-modal.priorityZoneCopyModal
                      :disabled="disableCopy"
                      variant="info"
                      size="sm"
                      class=""
                      @click="onCopy"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="CopyIcon" /> Sao chép điểm
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  :columns="columns"
                  :rows="dataSources"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :line-numbers="true"
                  @on-page-change="onPageChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span v-else-if="props.column.field === 'action'">
                      <b-button
                        v-if="updatable"
                        v-b-modal.priorityZoneSaveModal
                        variant="primary"
                        class="btn-icon"
                        size="sm"
                        @click="onEdit(props.row)"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                      <b-button
                        v-if="deletable"
                        variant="danger"
                        class="btn-icon"
                        style="margin-left: 6px"
                        size="sm"
                        @click="onDelete(props.row)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </span>
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <PriorityZoneSave
      :data-source="currentDataSource"
      @succeed="onSucceed"
    />
    <PriorityZoneCopy
      ref="priorityZoneCopyFormRef"
      :admission-round-id-copy="currentAdmissionRoundId"
      @succeed="onSucceed"
    />
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormGroup, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { STATUSES } from '@/const/status'
import PriorityZoneSave from '@/views/admission/admission-priority-score/PriorityZoneSave.vue'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import PriorityZoneCopy from './PriorityZoneCopy.vue'

export default {
  name: 'Index',
  directives: {
    Ripple,
  },
  components: {
    PriorityZoneCopy,
    BFormGroup,
    vSelect,
    PriorityZoneSave,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
  },
  data() {
    return {
      isLoading: false,
      currentUser: getUser(),
      filter: {
        admissionRoundId: null,
        admissionFormId: null,
        currentPage: 1,
        itemsPerPage: 10,
        code: null,
        name: null,
        status: null,
        checkInput: 1,
      },
      currentDataSource: undefined,
      currentAdmissionRoundId: undefined,
      paginationOptions: {
        enabled: true,
      },
      disableCopy: true,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
    }
  },
  computed: {
    ...mapGetters({
      admissionRounds: 'admissionRound/admissionRoundsByOrganization',
      dataListAdmissionForm: 'admissionForm/admissionFormByAdmissionRoundId',
      dataSources: 'admissionPriorityScore/admissionPriorityScores',
      totalRows: 'admissionPriorityScore/totalRows',
    }),
    columns() {
      return [
        {
          label: 'Đợt tuyển sinh',
          field: 'admissionRoundName',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Phương thức tuyển sinh',
          field: 'admissionFormName',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Khu vực ưu tiên',
          field: 'factPriorityZoneName',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Điểm ưu tiên',
          field: 'priorityScore',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          width: '100px',
          sortable: false,
          tdClass: 'text-center',
        },
      ]
    },
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.ADMISSION_PRIORITY_SCORE)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.ADMISSION_PRIORITY_SCORE)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.ADMISSION_PRIORITY_SCORE)
    },
  },
  watch: {
    async resource() {
      await this.onLoad()
    },
  },
  async created() {
    await this.onLoad()
  },
  methods: {
    ...mapMutations({
      setDataSources: 'admissionPriorityScore/SET_ADMISSION_PRIORITY_SCORES',
    }),
    ...mapActions({
      getDataSources: 'admissionPriorityScore/readAdmissionPriorityScores',
      deleteDataSource: 'admissionPriorityScore/deleteAdmissionPriorityScores',
      readAdmissionFormByAdmissionRoundId: 'admissionForm/readAdmissionFormByAdmissionRoundId',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
    }),
    async readAdmissionForm(id) {
      this.disableCopy = id === null
      await this.readAdmissionFormByAdmissionRoundId({ admissionRoundId: id })
    },
    async onLoad() {
      this.isLoading = true
      this.dataSources = []
      try {
        await this.getAdmissionRoundsByOrganization({ organizationId: this.currentUser.orgId })
        await this.getDataSources(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.setDataSources([])
      } finally {
        this.isLoading = false
      }
    },
    async btnSearchClick() {
      this.isLoading = true
      try {
        await Promise.all([
          this.getDataSources(this.filter),
        ])
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onCreate() {
      this.currentDataSource = undefined
    },
    onCopy() {
      this.currentAdmissionRoundId = this.filter.admissionRoundId
    },
    onEdit(dataSource) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = dataSource
      this.currentDataSource = rest
    },
    onDelete(dataSource) {
      this.$swal({
        title: 'Bạn chắc chắn muốn xóa Điểm quy đổi, điểm ưu tiên',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.isLoading = true
            try {
              const response = await this.deleteDataSource(dataSource.id)
              if (response) {
                const {
                  isSuccessful,
                  message,
                } = response
                if (isSuccessful) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  try {
                    await this.getDataSources(this.filter)
                  } catch (e) {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: `[${e.code}] ${e.message}`,
                        icon: 'XCircleIcon',
                        variant: 'danger',
                      },
                    })
                  }
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              }
            } catch (e) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `[${e.code}] ${e.message}`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            } finally {
              this.isLoading = false
            }
          }
        })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getDataSourcesFromStore()
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getDataSources(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({
        currentPage: param.currentPage,
        itemsPerPage: param.currentPerPage,
      })
      await this.getDataSourcesFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getDataSourcesFromStore()
    },
    async onSucceed() {
      await this.getDataSourcesFromStore()
    },
    getStatusName(id) {
      return STATUSES.find(status => status.value === id).label
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
