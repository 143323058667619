<template>
  <validation-observer
    ref="certificateCopyFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="certificateCopyModal"
      body-class="position-static"
      centered
      :title="`Sao chép điểm ưu tiên cho Thành tích học tập`"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="admissionRoundId">
          <template v-slot:label>
            Đợt tuyển sinh <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Đợt tuyển sinh"
            rules="required"
          >
            <v-select
              v-model="targetData.admissionRoundId"
              :options="admissionRounds"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Trạng thái"
            rules="required"
          >
            <v-select
              v-model="targetData.status"
              :options="statusOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Sao chép
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('certificateCopyModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BModal,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'

export default {
  name: 'ConfirmationCopy',
  components: {
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    admissionRoundIdCopy: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetData: {
        admissionRoundId: '',
        status: 1,
      },
      required,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      statuses: 'admissionRound/statuses',
      admissionRounds: 'admissionRound/admissionRoundsByOrganization',
    }),
    statusOptions() {
      return this.statuses.map(item => ({ value: item.value, label: item.label }))
    },
    isCreated() {
      return !this.admissionRoundIdCopy
    },
  },
  methods: {
    ...mapActions({
      copyPriorityScore: 'admissionPriorityScore/copyPriorityScore',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.isLoading = true
      if (this.admissionRoundIdCopy) {
        this.targetData.admissionRoundId = this.admissionRoundIdCopy
        await Promise.all([
          this.getAdmissionRoundsByOrganization({ organizationId: getUser().orgId }),
        ])
      }
      this.isLoading = false
    },
    onHide() {
      this.$refs
        .certificateCopyFormRef
        .reset()
      this.targetData = {
        admissionRoundId: '',
        status: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .certificateCopyFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        const formData = new FormData()
        formData.append('input', 3)
        formData.append('admissionRoundId', this.targetData.admissionRoundId)
        formData.append('status', this.targetData.status)
        try {
          const params = {
            id: this.admissionRoundIdCopy,
            params: formData,
          }
          const response = await this.copyPriorityScore(params)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('certificateCopyModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
